.app__menu {
	background-color: #352c2c;
	flex-direction: column;
}

.app__menu-menuList{
	justify-content: flex-start;
    align-items: baseline;
}

.app__menu-heading {
	text-align: center;
	justify-content: center;
}

.app__menu-menuList_type {
	color: var(--color-white);
	font-family: var(--font-base);
	font-weight: 600;
	font-size: 45px;
	line-height: 58.5px;
	letter-spacing: 0.04em;
	margin-bottom: 2rem;
	text-align: center;
}

.app__menu-menuList_wines,
.app__menu-menuList_cocktails {
	flex: 1;
	width: 100%;
	flex-direction: column;

	margin: 0rem 1rem;
}

.app__menu-menuList_img {
	width: 410px;
	margin: 0.2rem;
}

.app__menu-menuList_img img {
	width: 100%;
	height: auto;
}

.app__menu-menuList_items {
	display: flex;
	flex-direction: column;

	margin: 2rem 0rem;
	width: 100%;
}

@media screen and (max-width: 1150px) {
	.app__menu-menuList {
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	.app__menu-menuList_img {
		margin: 3rem 0;
	}

	.app__menu-menuList_wines,
	.app__menu-menuList_cocktails {
		margin: 2rem;
	}
}
@media screen and (max-width: 650px) {
	.app__menu-menuList_items,
	.app__menu-menuList_img {
		width: 80%;
	}
	.app__menu-heading {
		font-size: 35px;
		line-height: 48.5px;
	}
}
