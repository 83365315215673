.app__aboutus {
	position: relative;
}

.app__aboutus-overlay {
	position: absolute;
	inset: 1;
}

.app__aboutus-overlay img {
	width: 300px;
	height: 415px;
	z-index: 0;

	opacity: 0.1;
}

.app__aboutus-content {
	width: 100%;
	z-index: 2;
}

.app__aboutus-content_about {
	flex: 1;
	flex-direction: column;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;

	text-align: right;
}

.app__aboutus-content_knife {
	margin: 2rem 4rem;
}

.app__aboutus-content_knife img {
	height: 700px;
}

.app__aboutus-content_history {
	flex: 1;
	flex-direction: column;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;

	text-align: left;
}

.app__aboutus-content_history img {
	transform: rotateY(180deg);
}

.app__aboutus-content p {
	margin: 2rem 0rem;
}

@media screen and (max-width: 2000px) {
	.app__aboutus-content_knife img {
		height: 1110px;
	}

	.app__aboutus-content p {
		margin: 4rem 0rem;
	}
}

@media screen and (max-width: 900px) {
	.app__aboutus-content {
		flex-direction: column;
	}
	.app__aboutus-content_knife {
		margin: 4rem 0rem;
	}
}

@media screen and (max-width: 650px) {
	.app__aboutus-overlay img {
		width: 80%;
		height: 320px;
	}
}
