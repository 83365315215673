.app__gallery {
	display: flex;
	flex-direction: row;

	background-color: var(--color-black);
	padding: 4rem 0 4rem 6rem;
}

.app__gallery-content {
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	align-items: center;

	min-width: 500px;
	padding-right: 2rem;
}

.app__gallery-content p {
	color: #aaa;
	margin-top: 2rem;
}

.app__gallery-content button {
	margin-top: 1rem;
}

.app__gallery-images {
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: row;
	max-width: 50%;
}

.app__gallery-images_container {
	display: flex;
	flex-direction: row;
	width: max-content;
	overflow-x: scroll;
	scrollbar-width: none;
}

.app__gallery-images_container::-webkit-scrollbar {
	display: none;
}

.app__gallery-images_card {
	position: relative;
	min-width: 301px;
	height: 450px;
	margin-right: 2rem;
}

.gallery__images-icon {
	position: absolute;
	color: #fff;
	font-size: 2rem;
	opacity: 0.7;
	transition: 0.5s ease;
	cursor: pointer;
}

.app__gallery-images_card img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	opacity: 1;
	transition: 0.5s ease;
}

.app__gallery-images_card:hover img {
	opacity: 0.5;
}

.app__gallery-images_card:hover .gallery__images-icon {
	opacity: 1;
}

.app__gallery-images_arrows {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 0 1rem;
	position: absolute;
	bottom: 5%;
}

.gallery__arrow-icon {
	color: var(--color-golden);
	font-size: 2rem;
	background: var(--color-black);
	cursor: pointer;
	border-radius: 8px;
}

.gallery__arrow-icon:hover {
	color: var(--color-white);
}
.app__gallery-icon {
	color: white;
}

@media screen and (min-width: 2000px) {
	.app__gallery-content {
		min-width: 1000px;
		padding-right: 4rem;
	}

	.app__gallery-images_card {
		min-width: 400px;
		height: 550px;
	}
}

@media screen and (max-width: 1150px) {
	.app__gallery {
		flex-direction: column;
	}

	.app__gallery-content {
		min-width: 100%;
	}

	.app__gallery-images {
		max-width: 100%;
		margin: 5rem 0;
	}
}

@media screen and (max-width: 850px) {
	.app__gallery {
		padding: 4rem 0 4rem 4rem;
	}
}

@media screen and (max-width: 650px) {
	.app__gallery {
		padding: 4rem 0 4rem 1rem;
	}

	.app__gallery-content {
		width: 100%;
	}

	.app__gallery-images_card {
		min-width: 240px;
		height: 320px;
	}
}
