.app__menuItem {
	width: 100%;
	margin: 1rem 0;

	display: flex;
	flex-direction: column;
}

.app__menuItem-head {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.app__menuItem-sub {
	width: 100%;
	margin-top: 0.2rem;
}

.app__menuItem-name {
	flex: 1;
}

.box-name{
	width: 110px;
	min-width: 110px;
	max-width: 110px;
}

.app__menuItem-name {
	display: flex;
	
	justify-content: flex-end;
	align-items: flex-end;
}

.app__menuItem-dash {
	width: 100%;
	height: 1px;
	background-color: var(--color-golden);
	margin: 0 1rem;
	display: flex;
}
